@import "../assets/scss/colors";

.site {
    &__footer {
        z-index: 2;
        position: relative;
        padding: 60px 0;
        a {
            color: $white;
            &:hover {
                color: $red;
                text-decoration: underline;
                text-decoration-color: $red;
            }
        }
        .menu li.active a {
            display: inline-block;
            border-bottom: 1px solid $red;
        }
        .widgets {
            justify-content: space-between;
            .col {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: calc((100% - 70px) / 2);
                &:first-child,
                &:nth-child(2) {
                    flex-basis: calc((100% - 70px) / 4);
                }
            }
        }
        .footer--bottom {
            display: flex;
            align-items: flex-end;
        }
        .brand {
            .site__logo {
                margin: 0;
                svg {
                    height: 50px;
                    width: auto;
                }
            }
        }
        .widgets {
            margin-bottom: 100px;
            .widget { 
                font-size: 18px;
                line-height: 28px;
                font-weight: 400;
                box-sizing: border-box;
                border-top: 2px solid $red;
                padding-top: 30px;
                ul {
                    margin-top: 0;
                }
                &__title {
                    margin-top: 0;
                }
                a {
                    text-decoration: none;
                    color: $black;
                }
                .menu-term {
                    font-weight: normal;
                }
            }
        }
        .socials {
            flex-basis: 160px;
            align-items: center;
            margin: 0 auto;
            .list {
                margin: 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                li > a > span {
                    display: block;
                    width: 28px;
                    height: 28px;
                    background-color: $black;
                }
            }
            img {
                width: 28px;
                height: 28px;
                display: block;
            }
            a {
                display: block;
            }
            ul {
                align-items: center;

            }
        }

        .others {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            &, a {
                font-size: 14px;
                color: $black;
            }
            .list {
                margin: 0;
                flex-direction: row;
                margin-right: 0.618em;
                li {
                    margin-right: 0.382em;
                }
            }
        }
    }
}

@mixin mobile() {

    .site__footer {
        .footer--bottom {
            // padding-left: 1em;
        }
        .widgets {
            margin-bottom: 10px;
            .widget {
                font-size: 14px;
                line-height: 24px;
                padding-top: 10px;
                padding-bottom: 10px;
                margin-right: 0;
                font-weight: normal;
            }
            flex-direction: column;
            .col {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 100%;
            }
        }

        .others {
            flex-basis: 100%;
            .list {
                flex-direction: column;
                padding-bottom: 12px;
            }
        }
        .copy-right {
            font-size: 14px;
            line-height: 24px;
            order: 1;
            padding-top: 10px;
            margin-bottom: 50px;
            flex-basis: 100%;
            border-top: 2px solid $red;
        }

        .brand {
            order: 2;
        }
        .socials {
            order: 3;
            margin-left: auto;
            margin-right: 0;
            margin-bottom: 8px;
        }

        .site__logo img {
            height: 17px;
        }

        .socials img {
            width: 17px;
            height: 17px;
        }
    }
}

@media only screen and (max-width: 768px) {
    @include mobile();
}

@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
    /* CSS */
    @include mobile();
}