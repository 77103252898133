@import '../../../assets/scss/colors';
/* Move it (define the animation) */
@keyframes fly-in-right {
    0%   { 
        translate: 10% 0;	
        opacity: 0.3;	
    }
    100% { 
        translate: 0 0;
        opacity: 1;
    }
}
@keyframes fly-in-left {
    0%   { 
        translate: -10% 0;	
        opacity: 0.3;	
    }
    100% { 
        translate: 0 0;
        opacity: 1;
    }
}

.illustration {
    position: relative;
    width: 100%;
    height: 100%;
    &__wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 50%;
        translate: -50% 0;
        pointer-events: none;
        z-index: 999;
    }

    img {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: auto;
        height: 400px;
        animation: fly-in-right 600ms linear;
        animation-fill-mode: forwards;
    }
    .unikbase {
        
        &--brand {
            position: absolute;
            top: 32px;
            right: 90%;
            opacity: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 76px;
            .wrapper {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                > span {
                    font-size: 16px;
                    line-height: 1em;
                    margin-right: 1em;
                    text-transform: lowercase;
                    white-space: nowrap;
                }
            }
            animation: fly-in-left 600ms linear;
            animation-fill-mode: forwards;
        }
        &__logo svg {
            height: 44px;
            width: auto;
        }
    }

}

header {
    @media screen and (max-width: 900px) {
        background-color: $light-gray!important;
        .header {
            z-index: -1;
            background-color: $white;
        }
        > .section.bg--gray {
            background-color: transparent;
        }
        .illustration {
            &__wrapper {
                z-index: -1;
                .unikbase {
                    &--brand {
                        right: auto;
                        left: -50%;
                        scale: 0.618;
                    }
                }
            } 
        }
    }
}