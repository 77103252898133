@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,600;6..12,700&display=swap");
ul {
    list-style: none;
    padding-left: 0;
}

@import "../../assets/scss/colors";
@import "../../assets/scss/responsive";

@font-face {
    font-family: Apercu;
    src: url("../../../public/fonts/Apercu Regular.otf");
}
@font-face {
    font-family: Apercu;
    src: url("../../../public/fonts/Apercu Medium.otf");
    font-weight: 600;
}
@font-face {
    font-family: Apercu;
    src: url("../../../public/fonts/Apercu Medium Italic.otf");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: Apercu;
    src: url("../../../public/fonts/Apercu Italic.otf");
    font-style: italic;
}
@font-face {
    font-family: Apercu;
    src: url("../../../public/fonts/Apercu Bold.otf");
    font-weight: bold;
}
@font-face {
    font-family: Apercu;
    src: url("../../../public/fonts/Apercu Bold Italic.otf");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: Cormorant;
    src: url("../../../public/fonts/cormorant.regular.otf");
}
@font-face {
    font-family: Cormorant;
    src: url("../../../public/fonts/cormorant.bold.otf");
    font-weight: bold;
}
.invalid {
    input {
        border: 0.1rem solid red;
        background-color: #3f1d1da4;
        transition: 300ms;
        &:hover {
            border: 0.1rem solid red;
            background-color: #303245;
        }
    }
    p {
        color: rgb(218, 63, 63);
        margin-top: 0;
        transition: 400ms;
    }
}

$black: $black-ars;
$white: #ffffff;
$red: $yellow-ars;
$light-red: #ebad99;
$gray: $gray-ars;
$light-gray: $light-gray-ars;

.bg {
    &--black {
        background-color: $black;
    }
    &--gray {
        background-color: $light-gray;
    }
}

/* 3D Cube */
$cubeWidth: 70px;
$cubeHeight: 70px;
$cubeDepth: $cubeHeight;

._3d-wrap {
    position: relative;
    transform: scale(0.8);
    .image--3d {
        opacity: 0;
    }
    .space3d {
        perspective: 1000px;
        width: $cubeWidth;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-80%, 0);
        text-align: center;
        display: inline-block;
    }
    ._3dbox {
        display: inline-block;
        transition: all 0.85s cubic-bezier(0.175, 0.885, 0.32, 1.275);

        text-align: center;
        position: relative;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transform: rotateX(-15deg) rotateY(15deg);
        ._3dface {
            overflow: hidden;
            position: absolute;

            border: 1px solid #888;
            background: #fff;
            box-shadow:
                inset 0 0 60px rgba(0, 0, 0, 0.1),
                0 0 50px rgba(0, 0, 0, 0.3);
            color: #333;
            line-height: $cubeWidth;
            opacity: 0.8;
            img {
                height: 100%;
                width: auto;
            }
            &--front {
                // width: $cubeWidth;
                // height: $cubeHeight;
                height: 100%;
                width: auto;
                transform: translate3d(0, 0, calc($cubeDepth / 2));
            }
            &--top {
                width: 150px;
                height: $cubeDepth;
                transform: rotateX(90deg) translate3d(0, 0, calc($cubeHeight / 2));
            }
            &--left {
                width: $cubeDepth;
                height: $cubeHeight;
                left: 50%;
                height: 100%;
                margin-left: calc($cubeDepth / -2);
                transform: rotateY(-90deg) translate3d(0, 0, calc($cubeWidth / 2));
            }
        }
    }
}

.page__ARS {
    button {
        border-radius: 20px !important;
    }

    .header {
        .row {
            position: relative;
            .brand {
                margin-left: auto;
                margin-right: auto;

                .unikbase--brand {
                    display: none;
                    position: absolute;
                    top: 5px;
                    left: 15px;
                }

                @media screen and (max-width: 500px) {
                    align-items: center;
                    .unikbase--brand {
                        position: relative;
                        top: 10px;
                        left: 15px;
                    }
                }
            }
        }
    }
}

.site__ARS {
    width: 100vw;
    overflow: hidden;
    background-color: $white;
    box-sizing: border-box;
    color: $black;
    a {
        color: $black;
    }
    button {
        background-color: $white !important;
        color: $black !important;
        border: 1px solid $black !important;
        text-transform: none !important;
    }
    font-family:
        "Apercu",
        sans-serif,
        system-ui,
        -apple-system !important;

    * {
        font-family:
            "Apercu",
            sans-serif,
            system-ui,
            -apple-system !important;
    }
    &__title {
        color: $white;
        text-decoration: underline;
        text-decoration-color: $red;
        font-size: 39px;
        line-height: 49px;
    }

    &__description {
        padding-top: 2em;
        padding-bottom: 2em;
        .description__content {
            counter-reset: p-tag;
            p {
                counter-increment: p-tag;
                position: relative;
                margin-left: 2em;
                padding-bottom: 1em;
                border-bottom: 2px solid red;
                &:last-child {
                    border-bottom: none;
                }
                &::before {
                    content: "0" counter(p-tag) "\a0\a0";
                    font-weight: 600;
                    position: absolute;
                    left: -2em;
                    top: 0;
                }
            }
        }
    }

    &__faq {
        padding: 2em 0;
        &-item {
            border-top: 1px solid $white !important;
            padding-left: 0 !important;
            margin-left: 2em !important;
        }
    }
    $logo-height: 70px;
    &__logo {
        position: relative;
        display: block;
        &--image {
            display: flex;
            position: relative;
            justify-content: center;
            height: $logo-height;
            overflow: hidden;
            svg {
                height: $logo-height;
                width: auto;
            }
        }
    }
    .navigation {
        margin-right: 32px;
        padding-right: 8px;
        justify-content: center;
        @media screen and (max-width: 900px) {
            justify-content: flex-end;
        }
        .dropdown-menu {
            .handle {
                &::after {
                    width: 0;
                    height: 0;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 4px solid $black;
                    right: -8px;
                    position: absolute;
                    top: 16px;
                    translate: 0 -50%;
                }
            }
            .menu.menu--languages {
                display: none;
                position: relative;
                left: 0;
                margin: 0;
                &::after {
                    display: none;
                }
                li {
                    line-height: 32px;
                    cursor: pointer;
                }
                &.active {
                    display: flex;
                }
            }
        }
    }
    .unikbase-data {
        padding-top: 32px !important;
        padding-bottom: 0 !important;
        @media screen and (max-width: 1000px) {
            > .row {
                align-items: center;
                #about-tpk .content {
                    margin-top: 0;
                    margin-bottom: 32px;
                    &::before {
                        width: 100%;
                    }
                }
                &:first-child {
                    flex-direction: column-reverse;
                }
            }
        }
    }

    #about-tpk {
        flex-grow: 1;
        margin-left: 32px;
        @media screen and (max-width: 1000px) {
            margin-left: 0;
        }
        .content {
            padding: 32px;
            position: relative;
            &::before {
                content: " ";
                position: absolute;
                width: 200%;
                height: 100%;
                background: $light-gray;
                z-index: 1;
                top: 0;
                left: 0;
            }
            > .col {
                z-index: 2;
                flex-basis: 80%;
            }
            h3 {
                color: $black;
                font-family: Cormorant, system-ui !important;
                font-weight: bold;
            }
            h4 {
                margin-top: 1em;
                font-size: 20px;
                line-height: 1.5em;
            }
        }
        .illustration {
            position: absolute;
            width: 25%;
            max-width: 560px;
            max-height: 560px;
            right: 0;
            top: -120px;
        }
    }
    #product {
        max-width: 380px;
        justify-content: center;
        flex-direction: column;
        display: flex;

        .thumbnail {
            height: auto;
            padding: 0 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            position: relative;
            &__wrap {
                width: 60%;
                aspect-ratio: 1;
                position: relative;
                overflow: hidden;
                background-repeat: no-repeat;
                background-size: 100% auto;
                background-position: center;
            }
            img {
                height: auto;
                width: 100%;
            }
        }
        .product {
            &__detail {
                height: 100%;
                padding: 36px;
                color: #505050;
                a {
                    color: #505050;
                }
                h3 {
                    margin-top: 0;
                }
                h4 {
                    margin: 0;
                }
                p {
                    margin-top: 0;
                }
            }
            &__title {
                font-size: 20px;
                text-align: center;
                margin: 12px 0 8px 0;
                line-height: 1.5em;
                font-family: Cormorant, system-ui !important;
                font-weight: bold;
            }
            &_description {
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                display: inline-block;
                width: 100%;
            }
        }
    }
    #pricing-plans-ars {
        @media screen and (min-width: 1001px) {
            padding-top: 32px;
        }
        @media screen and (max-width: 1000px) {
            flex-wrap: wrap;
            .options {
                flex-basis: 100%;
            }
            .options + .col {
                flex-basis: 100%;
            }
        }
        flex-wrap: wrap;
        $item_height: 108px;
        .column__title {
            padding: 32px 0;
            .option_title {
                color: #000;
                background-color: $light-gray;
                margin-left: 30px;
                margin-right: 30px;
                padding: 5px 10px;
                border-radius: 8px;
                border: 1px solid #595959;
                width: 80%;
                white-space: break-spaces;
                text-align: center;
            }
        }
        .options {
            background-color: $black;
            h3 {
                margin: 0;
                font-size: 24px;
                font-weight: 600;
                line-height: 44px;
                color: $white;
                text-align: center;
                overflow: hidden;
                border-bottom: 2px solid $black;
                padding: 0 36px;
            }
            .list {
                padding: 0 36px;
                margin-top: 1em;
                li {
                    display: flex;
                    align-items: center;
                    height: $item_height;
                    overflow: hidden;
                    font-size: 18px;
                    line-height: 1.5em;
                }
            }
        }
        > .col--1 {
            padding: 70px 0;
            background-color: $light-gray;
            .row {
                width: 80%;
                margin: 0 auto;
            }
        }
        .pricing_table {
            &__column {
                width: 100%;
                border-radius: 10px;
                flex-basis: calc((100% - 18px * 2) / 3);
                background-color: $white;
                @media screen and (max-width: 1000px) {
                    margin-bottom: 32px;
                }
                &:nth-child(2) {
                    .column__title {
                        background-color: $red;
                        color: $white;
                        border-bottom-color: transparent;
                        h3 {
                            color: $white;
                        }

                        button {
                            border-color: $white !important;
                        }
                    }
                }
                > .MuiCard-root {
                    display: "flex";
                    flex-direction: "column";
                    border-radius: "0.8rem";
                    box-shadow: "0rem 0.5rem 0.2rem 0.2rem #000";
                    padding: 1;
                    border-radius: 12px;
                    height: 100%;
                    background-color: transparent;
                }
                .MuiCardContent-root {
                    padding: 0;
                }
                .column {
                    &__title {
                        position: relative;
                        font-weight: 700;
                        white-space: nowrap;
                        color: $white;
                        background-color: $black;
                        border-bottom: 2px solid $black;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        h3 {
                            display: flex !important;
                            flex-direction: column;
                            justify-content: center;
                            font-size: 1.5rem;
                            line-height: 2.618em;
                            text-transform: uppercase;
                            &,
                            > span {
                                color: $white;
                                text-align: center;
                                display: block;
                            }
                            > span {
                                font-size: 20px;
                            }
                        }

                        h6 {
                            font-size: 3.618rem;
                            line-height: 1.312em;
                            font-weight: 700;
                            position: relative;
                            display: inline-block;
                            .currency {
                                position: absolute;
                                font-size: 1.392rem;
                                top: 0;
                                right: -15px;
                                line-height: 1em;
                            }
                        }
                        .wrap {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    // &__title .wrap::before,
                    // &__title .wrap::after,
                    // &__title::after,
                    // &__title::before {
                    // 	content: " ";
                    // 	width: 24px;
                    // 	height: 24px;
                    // 	position: absolute;
                    // 	top: 0;
                    // 	left: 0;
                    // 	border-width: 4px 0 0 4px;
                    // 	border-color: $black;
                    // 	border-style: solid;
                    // 	display: block;
                    // }
                    // &__title::before {
                    // 	left: auto;
                    // 	right: 0;
                    // 	border-width: 4px 4px 0 0;
                    // }
                    // &__title .wrap::before {
                    // 	top: auto;
                    // 	bottom: 0;
                    // 	border-width: 0 0 4px 4px;
                    // }
                    // &__title .wrap::after {
                    // 	top: auto;
                    // 	left: auto;
                    // 	bottom: 0;
                    // 	right: 0;
                    // 	border-width: 0 4px 4px 0;
                    // }

                    &__button {
                        background-color: $red;
                        border-radius: 24px;
                        text-transform: uppercase;
                        color: $white;
                        font-weight: 600;
                        font-size: 18px;
                        letter-spacing: -0.6px;
                        margin-top: 10px;
                        white-space: nowrap;
                        &:hover {
                            background-color: darken($red, 5%);
                        }
                        &:disabled {
                            background-color: #757575;
                        }
                    }

                    &__description {
                        padding-top: 1em;
                        padding-bottom: 1em;
                        background-color: $white;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        .MuiSvgIcon-root {
                            margin-right: 0;
                        }
                        .option {
                            &__text {
                                // display: none;
                            }
                            @media screen and (max-width: 1000px) {
                                &__text {
                                    display: block;
                                }
                            }
                        }
                    }

                    &__actions {
                        padding-top: 12px;
                        background-color: transparent;
                    }
                }

                .option {
                    width: 100%;
                    padding: 0 1em;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    &__text {
                        display: inline-block;
                        text-align: left;
                        line-height: 36px;
                    }
                    &--cross {
                        .option__text,
                        .cross,
                        .tick {
                            opacity: 0;
                        }
                    }
                }
                .cross,
                .tick {
                    position: relative;
                    overflow: hidden;
                    color: $white;
                    display: inline-block;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    flex-basis: 36px;
                    flex-shrink: 0;
                    > span.icon {
                        height: 36px;
                        width: 36px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        svg {
                            color: $black;
                            font-weight: bold;
                        }
                    }
                    > span.note {
                        background-color: transparent;
                        line-height: 1.25em;
                        font-size: 14px;
                        color: #041c25;
                        margin-top: 4px;
                        position: absolute;
                        translate: 0 28px;
                    }
                    svg {
                        color: $white;
                        font-size: 20px;
                    }
                }

                .cross > span.icon {
                    background-color: $blue;
                }
            }
        }
        .pricing-plan-note {
            margin-top: 8px;
            font-size: 14px;
            font-style: italic;
            flex-basis: 100%;
            flex-shrink: 0;
        }
    }

    .video {
        &__player {
            position: relative;
            margin: auto;
            video {
                object-fit: contain;
                aspect-ratio: 1;
            }
            .play-button {
                position: absolute;
                top: 50%;
                left: 50%;
                translate: -50% -50%;
                width: 90px;
                height: 90px;
                display: block;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }

            &:not(.playing) {
                .play-button {
                    background-color: $black;
                    .arrow {
                        margin-left: 4%;
                        width: 0;
                        height: 0;
                        border-top: 12px solid transparent;
                        border-bottom: 12px solid transparent;
                        border-left: 24px solid $white;
                    }
                }
            }
            &.playing {
                .play-button {
                    background-color: rgba($color: $white, $alpha: 0.5);
                    display: none;
                    .arrow {
                        width: 50%;
                        height: 50%;
                        background-color: rgba($color: $white, $alpha: 0.3);
                    }
                }
                &:hover .play-button {
                    display: flex;
                }
            }
        }
    }

    .section {
        margin-bottom: 32px;
        @media screen and (max-width: 1366px) {
            max-width: calc(100% - 72px);
        }
        > .row {
            margin-left: -16px;
            margin-right: -16px;
            > .col {
                padding: 70px;
            }
        }
        h3 {
            font-size: 36px;
            line-height: 1.5em;
            font-weight: bold;
            font-family: Cormorant, system-ui !important;
        }
        &--bg-gray {
            background-color: #ebebeb;
        }
        &--bg-blue {
            background-color: $blue;
        }
        @media screen and (max-width: 1000px) {
            margin-bottom: 0;
            max-width: max-content;
            &:nth-child(2) {
                padding-top: 0;
            }
        }
    }

    #benefits {
        h3 {
            font-family: Cormorant, system-ui !important;
            font-weight: bold;
        }
        .list {
            .item {
                padding: 12px 0;
                border-bottom: 1px solid $white;
                &:last-child {
                    border-bottom: none;
                }
                &__title {
                    font-family: Cormorant, system-ui !important;
                    font-weight: bold;
                    font-size: 1.2em !important;
                }
                &__short {
                    font-size: 1em !important;
                    font-weight: normal !important;
                    font-style: italic;
                }
                span {
                    display: block;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 1.5em;
                }
            }
        }
        .benefit-cover-image {
            @media screen and (max-width: 1000px) {
                min-height: 500px;
            }
        }
    }

    #about-digital-passport {
        &.section > .row > .col:first-child {
            padding: 32px;
        }
        p {
            margin-bottom: 0.618em;
        }
    }
}

/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.loading:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));

    background: -webkit-radial-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.loading:not(:required):after {
    content: "";
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1250ms infinite linear;
    -moz-animation: spinner 1250ms infinite linear;
    -ms-animation: spinner 1250ms infinite linear;
    -o-animation: spinner 1250ms infinite linear;
    animation: spinner 1250ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow:
        rgba(104, 104, 104, 0.75) 1.5em 0 0 0,
        rgba(104, 104, 104, 0.75) 1.1em 1.1em 0 0,
        rgba(104, 104, 104, 0.75) 0 1.5em 0 0,
        rgba(104, 104, 104, 0.75) -1.1em 1.1em 0 0,
        rgba(104, 104, 104, 0.75) -1.5em 0 0 0,
        rgba(104, 104, 104, 0.75) -1.1em -1.1em 0 0,
        rgba(104, 104, 104, 0.75) 0 -1.5em 0 0,
        rgba(104, 104, 104, 0.75) 1.1em -1.1em 0 0;
    box-shadow:
        rgba(104, 104, 104, 0.75) 1.5em 0 0 0,
        rgba(104, 104, 104, 0.75) 1.1em 1.1em 0 0,
        rgba(104, 104, 104, 0.75) 0 1.5em 0 0,
        rgba(104, 104, 104, 0.75) -1.1em 1.1em 0 0,
        rgba(104, 104, 104, 0.75) -1.5em 0 0 0,
        rgba(104, 104, 104, 0.75) -1.1em -1.1em 0 0,
        rgba(104, 104, 104, 0.75) 0 -1.5em 0 0,
        rgba(104, 104, 104, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
