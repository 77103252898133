$black: #001c26;
$white: #FFFFFF;
$red: #EA5123;
$light-red: #EBAD99;
$gray: #EFEBE6;

$blue: #0A3E81;
$light-blue: #245BA1;
$bright-blue: #008FFF;
$gray: #484848;
$light-gray: #ebebeb;

$black-ars: #0a0b0b;
$light-gray-ars: #d7d1d1;
$yellow-ars: #f5d174;
$gray-ars: #808291;

:root {
	--unikbase-black: #{$black};
	--unikbase-white: #{$white};
	--unikbase-red: #{$red};
	--unikbase-gray: #{$gray};
	--unikbase-blue: #{$blue};
}