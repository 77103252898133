@import './colors';

.d-mobile {
    display: none!important;
}

.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
}

.menu {
    display: flex;
    flex-direction: column;
    &--horizontal {
        flex-direction: row;
    }
}

.flex {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    &--d {
        display: block!important;
    }
    &--column {
        flex-direction: column;
    }
    &--start {
        align-items: flex-start;
    }
    &--end {
        align-items: flex-end;
        justify-content: flex-end;
    }
    &--grow {
        flex-grow: 1;
        flex-shrink: 1;
    }
    &--no-wrap {
        flex-wrap: nowrap;
    }
    &--space-around {
        justify-content: space-around;
    }
    &--space-between {
        justify-content: space-between;
    }
    &--reverse {
        flex-direction: row-reverse;
    }
}
.container {
    box-sizing: border-box;
    position: relative;
    // width: 1120px;
    width: 1366px;
    margin-left: auto;
    margin-right: auto;
    .wrap {
        &--80 {
            width: 80%;
        }
    }
}

.dropdown-menu {
    position: relative; 
    .handle {
        cursor: pointer;
    }
    .menu {
        display: none;
        position: absolute;
    }
    &:hover,
    &--open {
        .menu {
            display: block;
        }
    }
}

.bg {
    &--white {
        background-color: $white;
    }
    &--blue {
        background-color: $blue;
        color: $white;
    }
    &--gray {
        background-color: $light-gray;
    }
    &--black {
        background-color: $black;
    }
}


.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    &--wrap {
        flex-wrap: wrap;
    }
    $gutter-width: 36px;
    justify-content: space-between;
    .col {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: calc((100% - $gutter-width) / 2);
        &--revert {
            flex-flow: row-reverse;
        }
        &--1 {
            flex-basis: 100%;
        }
        &--1-3 {
            flex-basis: calc((100% - $gutter-width * 2) / 3);
        }
        &--2-3 {
            flex-basis: calc( (100% - $gutter-width * 2) / 3 * 2 + $gutter-width);
        }

        &--4-12 {
            flex-basis: calc((100% - 36px)*0.4);
        }
        &--8-12 {
            flex-basis: calc((100% - 36px)*0.6);
        }
    }

    @media screen and (max-width: 900px) {
        flex-direction: column;
        .col {
            flex-basis: 100%;
        }
    }
}
