.video {
    &__player {
        width: 270px;
        padding-top: 5px;
        padding-bottom: 5px;
        aspect-ratio: 9/17.5;
        border-radius: 19px;
        overflow: hidden;

        @media screen and (max-width: 900px) {
            width: 300px;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
    &__holder {
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("../../../assets/iphone-mockup.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
}