.logo {
    &--thePackengers {
        background-image: url('../../assets/the-packengers-logo.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 76px;
        width: 268px;
        display: block;
    }    
    &--Artsper {
        background-size: contain;
        background-position: center;
        height: 150px;
        width: 600px;
        max-width: 95vw;
    }
}