@import "../../assets/scss/colors";
@import "../../assets/scss/utils";

.unikbase__logo {
	svg {
		@media screen and (max-width: 900px) {
			height: 60px;
		}
		@media screen and (max-width: 356px) {
			height: 60px;
		}
	}
}