@import "../assets/scss/colors";
@import "../assets/scss/utils";

header {
    .brand {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-end;
        .logo--thePackengers {
            margin-left: 70px;
        }
        .unikbase {
            &--brand {
                .wrapper {
                    display: flex;
                    align-items: flex-start;
                    > span {
                        font-size: 16px;
                    }
                }
            }
            &__logo {
                margin-left: 0.618em;
                svg {
                    height: 32px;
                }
            }
        }
    }
    position: relative;
    h3 {
        color: $blue;
    }
    .header {
        padding: 32px 0;
        position: relative;
    }
}

.site {
	&__navigation {
		&,
		a {
			color: $white;
			text-decoration: none;
		}
		a:hover {
			text-decoration: underline;
		}
		width: 100%;
		top: 0;
		z-index: 99;

		.logo--blue {
			display: none;
		}
		.main-navigation {
			display: none;
			margin: 0 auto;
			font-size: 24px;
			line-height: 28px;
			text-transform: none;
			li {
				margin-left: 1em;
				line-height: 1em;
				&:hover,
				&.active {
					a {
						text-decoration: underline;
						text-decoration-color: $red;
					}
				}
			}
		}
		&--white,
		&.navigation--sticky {
			&,
			a {
				color: $black;
			}
			.main-navigation {
				display: block;
			}

			.site__logo svg .cls-2 {
				fill: $black;
			}
		}

		&--white {
			background-color: $white;
			top: 0;
			z-index: 999;
			.brand {
				margin-right: auto;
			}
			.menu--mobile-float {
				background-color: $white;
				display: flex;
				align-items: flex-end;
				.menu {
					margin: 0;
				}
			}
			.sign-in.dropdown-menu {
				.handle::after {
					border-color: $black transparent transparent transparent !important;
				}
			}
		}
		.hamburger {
			cursor: pointer;
			display: none;
			margin-left: auto;
			z-index: 1;
			width: 24px;
			height: 24px;
			background: $black;
			display: flex;
			align-items: center;
			justify-content: center;
			position: fixed;
			/* Hamburger Line */
			& > div {
				position: relative;
				flex: none;
				width: 100%;
				background: $white;
				display: flex;
				align-items: center;
				justify-content: center;
				transform-origin: center;
				top: 50%;
				height: 2px;
				transform: translate(0, -50%);

				/* Hamburger Lines - Top & Bottom */
				&::after,
				&::before {
					content: "";
					position: absolute;
					z-index: 1;
					top: 50%;
					width: 100%;
					height: 2px;
					background: inherit;
					transition: transform 0.4s ease;
				}

				&::after {
					transform: rotate(0deg) translate(0, 150%);
				}
				&::before {
					transform: rotate(0deg) translate(0, -250%);
				}
			}
		}

		// Menu Float on mobile
		.menu--mobile-float {
			@extend .flex;
			flex-grow: 1;
			flex-shrink: 1;
			justify-content: flex-end;
		}

		.sign-in.dropdown-menu {
			pointer-events: all;
			margin-right: 1em;
			line-height: 2em;
			padding: 0 1em;
			.handle {
				padding-right: 1em;
				cursor: pointer;
				text-transform: uppercase;
				// &:hover {
				//     text-decoration: underline;
				//     color: $red;
				//     text-decoration-color: $red;
				// }
				&::after {
					content: " ";
					width: 0;
					height: 0;
					border-width: 3px 3px 0 3px;
					border-color: $white transparent transparent transparent;
					border-style: solid;
					position: absolute;
					right: 0;
					top: 50%;
					margin-top: -2px;
				}
			}
			.menu {
				border-radius: 2px;
				right: 1em;
				top: 100%;
				padding: 20px 0;
				border: none;
				li {
					line-height: 2em;
					background-color: $white;
					&:first-child {
						border-top-left-radius: 2px;
						border-top-right-radius: 2px;
					}
					&:last-child {
						border-bottom-left-radius: 2px;
						border-bottom-right-radius: 2px;
					}
				}
				&::after {
					content: ""; /* Required to display content */
					position: absolute; /* Sets the position absolute to the top div */
					top: 18px;
					right: 4px; /* position the little arrow */
					margin-left: -4px;
					margin-top: -4px; /* Set margin equal to border px */
					width: 0;
					z-index: 1;
					height: 0;
					border-bottom: solid 6px #fff; /* Creates the arrow pointing up, to change to a notch instead user border-top */
					border-left: solid 4px transparent; /* Creates triangle effect */
					border-right: solid 4px transparent; /* Creates triangle effect */
					border-radius: none;
				}

				&,
				a {
					color: $black;
				}
				li {
					padding: 0 1em;
				}
				a {
					white-space: nowrap;
					line-height: 1.75em;
				}
			}
		}
		.languages.dropdown-menu {
			font-weight: 300;
			.handle {
				color: $red;
				text-decoration: underline;
				text-decoration-color: $red;
				display: inline-block;
				width: 2em;
				line-height: 2em;
				text-align: center;
			}
			.menu {
				right: 0;
				width: 100%;
				text-align: center;
				li {
					line-height: 1.75em;
					display: block;
					&.active {
						display: none;
					}
					a {
						line-height: 2em;
					}
				}
			}
			// Decoration for corners
			.handle::after,
			.handle::before,
			.menu::after,
			.menu::before,
			&::after,
			&::before {
				content: " ";
				width: 4px;
				height: 4px;
				position: absolute;
				top: 0;
				left: 0;
				border-width: 1px 0 0 1px;
				border-color: $white;
				border-style: solid;
				display: block;
			}
			.handle::after {
				left: auto;
				right: 0;
				border-width: 1px 1px 0 0;
			}
			.menu::after,
			.menu::before {
				display: none;
			}

			.menu::after,
			&::after {
				top: auto;
				bottom: 0;
				border-width: 0px 0px 1px 1px;
			}
			.menu::before,
			&::before {
				top: auto;
				bottom: 0;
				right: 0;
				left: auto;
				border-width: 0px 1px 1px 0px;
			}
			&--open {
				&::after,
				&::before {
					display: none;
				}

				.menu::after,
				.menu::before {
					display: block;
				}
			}
		}
	}

	&--shown-navigation {
		.site__navigation.site__navigation--white {
			position: sticky;
			.hamburger {
				position: relative;
			}
		}
		.hamburger {
			> div {
				background-color: transparent;
				&::after,
				&::before {
					transform-origin: center;
					background-color: $white;
				}
				&::after {
					transform: translate(0, -50%) rotate(45deg);
				}
				&::before {
					transform: translate(0, -50%) rotate(-45deg);
				}
			}
		}
	}
}
