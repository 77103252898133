@import 'colors';

@mixin mobile() {
    .hide-mobile {
        display: none!important;
    }

    .d-mobile {
        display: block !important;
    }
    .container {
        width: 90%;
        .wrap--80 {
            width: 100%;
        }
    }
    
    .section {
        padding-top: 50px;
        padding-bottom: 0px;
    }
    header .brand .logo--thePackengers {
        margin-left: 32px;
    }
    .site {
        &__logo {
			a, svg  {
				height: 44px;
			}
		}
        &__navigation {
            .container {
                height: 70px;
                align-items: flex-start;
            }
            &--white,
            &.navigation--sticky {
                justify-content: flex-start;
                .hamburger {
                    margin-bottom: 5px;
                    border-radius: 50%;
                    padding: 5px;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    background-color: $red;
                }
            }
            .menu--mobile-float {
                position: absolute;
                z-index: 99;
                display: none;
                width: 100vw;
                left: 0;
                box-sizing: border-box;
                background-color: $black;
                min-height: 70vh;
                margin-top: 36px;
                margin-left: -5vw;
                padding: 5vw;

                top: 100%;
                left: 0;
                .navigation {
                    text-transform: none;
                    .menu {
                        flex-direction: column;
                        text-align: left;
                        li {
                            margin-left: 0;
                            font-size: 24px;
                            line-height: 34px;
                            &:hover,
                            &.active {
                                &, a {
                                    color: $red!important;
                                    text-decoration: none!important;
                                }
                            }
                        }
                        &:first-child {
                            margin-bottom: 50px;
                        }
                    }
                }
            }
            .hamburger {
                display: block;
                margin-left: auto;
            }
            .navigation.margin--l {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
            }
            &--white {
                background-color: transparent;
                .brand {
					#logo .cls-2 {
						color: #ffffff;
						fill: #ffffff;
					}
                }
                .container {
                    background-color: inherit;
                }
                .menu--mobile-float {
                    margin-top: 10px;
                    padding-top: 70px;
                    background-color: inherit;
                }
                .dropdown-menu.languages {
                    .menu li {
                        &::before,
                        &::after,
                        a::before,
                        a::after {
                            border-color: $black;
                        }
                    }
                }
            }
        }
        &--shown-navigation {
            .site__navigation {
                background-color: $black;
                &--white {
                    .site__logo svg .cls-2 {
                        fill: $white;
                    }
                    &, .container {
                        background-color: $black;
                        color: $white;
                    }
                    .brand {
                        display: block;
                    }
                }
                .menu--mobile-float {
                    display: block!important;
                    background-color: $black;
                    color: $white;
                    .navigation .menu li a {
                        color: $white;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 901px) {
    @include mobile();
}

@media only screen and (min-width: 901px) and (max-width: 1280px) and (orientation: portrait) {
    /* CSS */
    @include mobile();
}


@media screen and (min-width: 901px) and (max-width: 1366px) {
    .container {
        width: calc(100vw - 80px);
    }
}

@media screen and (min-width: 902px) {
    .site {
		&__navigation {
			&--white {
				.menu--mobile-float {
					align-items: flex-end;
				}
				.navigation {
					display: flex;
					flex-direction: column;
                    justify-content: center;
					.sign-in {
						font-size: 0.9em;
						margin-right: 0;
						.handle {
							padding-right: 0;
							padding-left: 1em;
							&::after {
								right: auto;
								left: 0;
							}
						}
					}
				}
			}
			.languages.dropdown-menu:hover {
				&::after,
				&::before {
					display: none;
				}
				
				.menu::after,
				.menu::before {
					display: block;
				}
			}
		}
        &__navigation--white {
            .navigation {
				.sign-in {
                    order: 2;
                }
                .menu--langauges {
                    order: 1;
                }
            }
        }
    }
}
@media screen and (min-width: 902px) and (max-width:1280px)  { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */ 

    .site {
        &__logo a {
            height: 60px;
        }
        &__navigation .main-navigation {
            font-size: 20px!important;
            line-height: 30px;
        }
    }
}

@media screen and (max-width: 767px) {
    .illustration__wrapper {
        display: none!important;
    }
    header .brand .logo--thePackengers {
        margin-left: 16px;
    }
    .site {
        &__navigation .navigation.margin--l {
            justify-content: flex-end;
        }
        .section > .row > .col {
            padding: 16px!important;
        }
    }
}