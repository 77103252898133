@import "../../assets/scss/colors";
@import '../../assets/scss/utils';

#instagram {
    padding-bottom: 0;
    .instagram {
        width: 100vw;
        margin-left: calc(-50vw + 50% + 10px);
        overflow-x: hidden;
        margin-top: 2.5em;
        &__feed {
            justify-content: space-between;
        }
        &__slider {
            width: 100vw;
            overflow: hidden;
            max-height: calc((100vw / 6)*2);
            @extend .flex;
            @extend .flex--start;
            &--placeholder {
                .instagram__post {
                    background-color: $gray;
                }
            }
        }
        &__post {
            flex-basis: calc(99.9vw / 6);
            height: calc(100vw / 6);
            overflow: hidden;
            flex-shrink: 0;
            flex-grow: 0;
            a {
                display: block;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        
    }
}